
// common.js

// this file is compiled by webpack and is not within the normal require.js compilation step.

// this file is only run if another require.js module requires this script at runtime.
// if modules installed from yarn/npm are needed, these can be imported here using ES6 
// import syntax and exported to the default object.



// for polyfills or other globally-available scripts, use common-init.js

import Player from '@vimeo/player';
import { CountUp } from 'countup.js';
import lity from 'lity';
import dragdealer from 'dragdealer';
import URI from 'urijs';

// templates

import filterStatus from './../../../views/search-form-filter-status.html';
import filterTags from './../../../views/search-form-filter-tags.html';
import jobs from './../../../views/search-form-jobs.html';
import pagination from './../../../views/search-form-pagination.html';
import status from './../../../views/search-form-status.html';
import sidebarStatus from './../../../views/sidebar-status.html';
import sidebarJobs from './../../../views/sidebar-jobs.html';
import sidebarSpinner from './../../../views/sidebar-spinner.html';
import sidebarTitle from './../../../views/sidebar-title.html';
import sidebarFilterStatus from './../../../views/sidebar-filter-status.html';


export default {
	Player,
	CountUp,
	lity,
	dragdealer,
	URI,
	searchTemplates : {
		filterStatus,
		filterTags,
		jobs,
		pagination,
		status
	},
	sidebarTemplates : {
		sidebarStatus,
		sidebarJobs,
		sidebarSpinner,
		sidebarTitle,
		sidebarFilterStatus
	}
}